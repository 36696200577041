.wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.page {
    flex-grow: 1;
    margin-top: 16px;
}

.footer {
    position: sticky;
    display: flex;
    margin: 16px;
    flex-shrink: 0;
}

.footer a {
    margin: 8px;
}

.page .MuiPaper-root {
    height: 100%;
    padding: 16px;
}

.page .google {
    font-size: 14px;
    text-transform: none;
    background-color: #4285f4;
    padding: 8px;
}

.page .google:hover {
    background-color: #0068d2;
}

.glogo {
    height: 18px;
    width: 18px;
    margin-right: 24px;
}

.account {
    display: flex;
}

.account img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
}

.mcform {
    display: inline-flex;
    flex-direction: column;
}

.page .mcname {
    width: 100%;
    margin-right: 16px;
    margin-bottom: 8px;
}

.page .mcerror {
    display: inline-block;
    background-color: #e24364;
    margin-left: 16px;
}

.spinner {
    display: flex;
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    justify-content: center;
}

.spinner svg {
    font-size: 5rem;
}

#profile {
    margin-left: auto;
    text-transform: none;
}

#profile img {
    margin-left: 8px;
}

@keyframes spin {
    from {
        transform:rotate(0deg) scale(1, -1);
    }
    to {
        transform:rotate(360deg) scale(1, -1);
    }
}

a, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
}